<template>
  <div class="ClippingPreviewFilesFile">
    <template v-if="isPrint">
      <light-gallery #default="{ trigger }" :item="file.url">
        <div
          class="ClippingPreviewImage__file"
          @click="trigger"
        >
          <img
            ref="image"
            :src="resizedImage"
            class="ClippingPreviewImage__fileSource print-preview"
            @load="initCropper"
          >
        </div>
      </light-gallery>
    </template>
    <template v-else-if="isVideo && !$isReport">
      <label class="label">
        {{ $t('pages.coverage.clipping_edit.video_preview') }}
      </label>
      <video
        :src="file.url"
        class="video-preview"
        controls
      />
    </template>
    <template v-else-if="isAudio && !$isReport">
      <label class="label">
        {{ $t('pages.coverage.clipping_edit.audio_preview') }}
      </label>
      <audio
        :src="file.url"
        class="is-full-width audio-preview"
        controls
      />
    </template>
    <img
      v-else
      :src="fallbackFilePreview"
      class="fallback-preview"
    >
  </div>
</template>

<script>
import Cropper from 'cropperjs'
import { isAudio, isImage, isVideo } from '@hypefactors/shared/js/utils/index'
import { ImageResizerService } from '@hypefactors/shared/js/services/ImageResizerService'
import { round } from '@hypefactors/shared/js/utils/numberUtils'

const LightGallery = () => import('../core/LightGallery')

const MAX_WIDTH = 1024
const MAX_HEIGHT = 960
/**
 * Renders a file attachment of a clipping.
 * Renders differently for each type.
 * @module ClippingPreviewFilesFile
 */
export default {
  components: { LightGallery },
  props: {
    isPrint: {
      type: Boolean,
      default: false
    },
    file: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      $cropper: null
    }
  },
  computed: {
    isVideo () {
      return isVideo(this.file.url)
    },
    isAudio () {
      return isAudio(this.file.url)
    },
    isImage () {
      return isImage(this.file.url)
    },
    resizedImage () {
      return ImageResizerService.resize(this.file.url, MAX_WIDTH, MAX_HEIGHT, { quality: 80 })
    },
    fallbackFilePreview () {
      if (!this.isImage) return this.$asset('/images/sections/performance/fallback_clipping_thumbnail.png')
      return this.resizedImage
    },
    /**
     * After resizing images, we need to see by how much we resized
     * @return {number|*}
     */
    resizeScale () {
      if (!this.file.imageData) return 1

      const { naturalWidth, naturalHeight } = this.file.imageData
      const shouldResizeWidth = naturalWidth > MAX_WIDTH
      const shouldResizeHeight = naturalHeight > MAX_HEIGHT

      // if we do not need resizing, just leave it to 1.
      if (!shouldResizeWidth && !shouldResizeHeight) return 1

      const resizeBy = shouldResizeWidth
        ? (MAX_WIDTH / naturalWidth)
        : (MAX_HEIGHT / naturalHeight)

      return round(resizeBy)
    },
    /**
     * After knowing the rezise scale, we need to adjust crop area data
     * @return {{width: number, x: number, y: number, height: number} | null}
     */
    resizedCropData () {
      const data = this.file.cropData
      const scale = this.resizeScale

      if (!data) return null

      return {
        ...data,
        width: round(scale * data.width),
        height: round(scale * data.height),
        x: round(scale * data.x),
        y: round(scale * data.y)
      }
    }
  },
  beforeDestroy () {
    this.destroyCropper()
  },
  methods: {
    async initCropper () {
      this.destroyCropper()
      await this.$nextTick()
      if (!this.isPrint || !this.$refs.image) return
      this.$cropper = new Cropper(this.$refs.image, {
        rotatable: false,
        autoCropArea: 1,
        viewMode: 2,
        data: this.resizedCropData,
        zoomable: false,
        cropBoxResizable: false,
        cropBoxMovable: false,
        zoomOnWheel: false,
        zoomOnTouch: false,
        dragMode: 'none',
        center: false
      })
    },
    destroyCropper () {
      this.$cropper && this.$cropper.destroy()
    }
  }
}
</script>

<style lang='scss'>
.el-carousel {
  .ClippingPreviewFilesFile {
    max-height: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ClippingPreviewImage__file {
    max-height: 100%;
    max-width: 100%;
  }

  .ClippingPreviewImage__fileSource {
    max-height: 100%;
  }
}

.ClippingPreviewImage__file .cropper-container {
  margin: auto;
  max-width: 100%;
  max-height: 100%;
}
</style>
